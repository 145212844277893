export var initNewsletter = function initNewsletter() {
  var wpcf7Containers = document.querySelectorAll(".newsletter .wpcf7-form");
  if (!wpcf7Containers.length) return;
  wpcf7Containers.forEach(function (newsletter) {
    var formParent = newsletter.closest(".newsletter");
    var newsletterResponse = formParent.querySelector(".newsletter__response");
    var wpcf7 = formParent.querySelector(".wpcf7");
    newsletter.addEventListener("wpcf7mailsent", function (e) {
      newsletterResponse.classList.add("is-send");
      setTimeout(function () {
        if (!newsletter.closest(".popup")) {
          wpcf7.style.opacity = 1;
          wpcf7.style.visibility = "visible";
          newsletterResponse.classList.remove("is-send");
        }
      }, 8000);
    }, false);
  });
};