export var initVideo = function initVideo() {
  var videoButton = document.querySelector('.build__video-play-btn');
  videoButton.addEventListener('click', function () {
    var videoContainer = videoButton.nextElementSibling;
    var url = videoContainer.getAttribute('data-link');
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    videoContainer.setAttribute('src', "https://www.youtube.com/embed/".concat(match[2], "?autoplay=1"));
    videoContainer.addEventListener('load', function () {
      videoButton.classList.add('is-disable');
    });
  });
};