import HandyCollapse from "handy-collapse";
var mainNav = document.querySelector(".navigation");
export var initHamburgerMenu = function initHamburgerMenu() {
  /* Hamburger */
  var hamburgerEl = document.querySelector(".hamburger");
  var mobileMenu = document.querySelector(".nav__center");

  var activeMenu = function activeMenu() {
    hamburgerEl.classList.toggle("is-active");
    mainNav.classList.toggle("is-active");
    mobileMenu.classList.toggle("is-active");
  };

  if (hamburgerEl) {
    hamburgerEl.addEventListener("click", function () {
      activeMenu();
    });
  }
};
export var initAccordionSubmenus = function initAccordionSubmenus() {
  var submenuAccordion = new HandyCollapse({
    nameSpace: "submenu"
  });
  var submenuChildrenAccordion = new HandyCollapse({
    nameSpace: "children"
  });
};