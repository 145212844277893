import HandyCollapse from "handy-collapse";

var initAccordions = function initAccordions() {
  setTimeout(function () {
    new HandyCollapse({
      nameSpace: "comfort",
      animationSpeed: 200
    });
  }, 500);
  setTimeout(function () {
    new HandyCollapse({
      nameSpace: "space",
      animationSpeed: 200
    });
  }, 500);
};

export var newInvest = function newInvest() {
  initAccordions();
};