import anime from 'animejs/lib/anime.es.js';
import 'waypoints/lib/noframework.waypoints';
export var drawingSVG = function drawingSVG() {
  var allDrawingElements = document.querySelectorAll('.svg-drawing');
  allDrawingElements.forEach(function (item) {
    new Waypoint({
      element: item,
      handler: function handler(direction) {
        var svgDuration = null;
        var svgDelay = 0;

        if (this.element.classList.contains('svg-drawing--slow')) {
          svgDuration = 7000;
        } else if (this.element.classList.contains('svg-drawing--delay')) {
          svgDuration = 3000;
          svgDelay = 1000;
        } else {
          svgDuration = 2000;
        }

        anime({
          targets: this.element.querySelectorAll('path'),
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeInOutSine',
          duration: svgDuration,
          delay: svgDelay
        });

        if (this.element.querySelectorAll('rect')) {
          anime({
            targets: this.element.querySelectorAll('rect'),
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: svgDuration,
            delay: svgDelay
          });
        }

        if (this.element.querySelectorAll('line')) {
          anime({
            targets: this.element.querySelectorAll('line'),
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: svgDuration,
            delay: svgDelay
          });
        }

        if (this.element.querySelectorAll('circle')) {
          anime({
            targets: this.element.querySelectorAll('circle'),
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: svgDuration,
            delay: svgDelay
          });
        }

        if (this.element.querySelectorAll('ellipse')) {
          anime({
            targets: this.element.querySelectorAll('ellipse'),
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: svgDuration,
            delay: svgDelay
          });
        }

        this.destroy();
      },
      triggerOnce: true,
      offset: '100%'
    });
  });
};