export var changeNumbers = function changeNumbers() {
  var footerTopNumber = document.querySelector(".footer-top__phone");
  var contactHeaderNumber = document.querySelector(".contact-header__phone-txt");
  var contactAdviserNumber = document.querySelector(".advisers__box-tel"); // UTM

  var urlParams = new URLSearchParams(window.location.search);
  var sourceUTMFromURL = urlParams.get("utm_source") ? urlParams.get("utm_source") : "";
  var mediumUTMFromURL = urlParams.get("utm_medium") ? urlParams.get("utm_medium") : "";
  var sourceUTMFromSession = sessionStorage.getItem("nowa-deweloper-utm-source") ? sessionStorage.getItem("nowa-deweloper-utm-source") : "";
  var mediumUTMFromSession = sessionStorage.getItem("nowa-deweloper-utm-medium") ? sessionStorage.getItem("nowa-deweloper-utm-medium") : "";
  var utmSource = (sourceUTMFromURL ? sourceUTMFromURL : sourceUTMFromSession).toLowerCase();
  var utmMedium = (mediumUTMFromURL ? mediumUTMFromURL : mediumUTMFromSession).toLowerCase();

  function changeNumber(el) {
    var spanElement = el.querySelector("span");

    if (utmSource === "facebook" && utmMedium === "post") {
      spanElement.textContent = "+48 34 307 21 38";
      el.setAttribute("href", "tel: +48343072138");
    } else if (utmSource === "facebook" && utmMedium === "cpc") {
      spanElement.textContent = "+48 34 307 21 35";
      el.setAttribute("href", "tel: +48343072135");
    } else if (utmSource === "google" && utmMedium === "cpc") {
      spanElement.textContent = "+48 34 307 21 33";
      el.setAttribute("href", "tel: +48343072133");
    }
  }

  if (footerTopNumber) {
    changeNumber(footerTopNumber);
  }

  if (contactHeaderNumber) {
    changeNumber(contactHeaderNumber);
  }

  if (contactAdviserNumber) {
    changeNumber(contactAdviserNumber);
  }
};