import $ from "jquery";
export var mainPopup = function mainPopup() {
  var globalPopup = document.querySelector(".main-popup");
  if (!globalPopup) return;

  function setSessionState() {
    sessionStorage.setItem("deweloper-main-popup", true);
  }

  $(".main-popup__content").on("click", function () {
    setSessionState();
  });
  $(".main-popup__btn").on("click", function () {
    $(globalPopup).fadeOut("slow");
    setSessionState();
    return false;
  });
  $(".main-popup__bg").on("click", function () {
    $(globalPopup).fadeOut("slow");
    setSessionState();
    return false;
  });

  if (sessionStorage.getItem("deweloper-main-popup")) {
    globalPopup.classList.add("is-hide");
  } else {
    setTimeout(function () {
      $(globalPopup).fadeIn("slow");
    }, 4000);
  }
};