var mediaQuery = window.matchMedia("(min-width: 992px)");
export var addClassOnScroll = function addClassOnScroll() {
  var header = document.querySelector(".nav-helper");
  var mainNav = document.querySelector("#navigation");
  var scrollTopBtn = document.querySelector(".scroll-top");
  var bodyEl = document.querySelector("body"); // const appointmentBar = document.querySelector(".appointment-bar");
  // const closeBtn = appointmentBar.querySelector(".appointment-bar__close-btn");

  var observer = new IntersectionObserver(function (changes) {
    var _changes$, _changes$2;

    if (((_changes$ = changes[0]) === null || _changes$ === void 0 ? void 0 : _changes$.isIntersecting) === true || ((_changes$2 = changes[1]) === null || _changes$2 === void 0 ? void 0 : _changes$2.isIntersecting) === true) {
      mainNav.classList.add("scroll-active");
      bodyEl.classList.add("scroll--is-active");

      if (scrollTopBtn) {
        scrollTopBtn.classList.add("is-active");
      } // appointmentBar.classList.add("active");

    } else {
      mainNav.classList.remove("scroll-active");
      bodyEl.classList.remove("scroll--is-active");

      if (scrollTopBtn) {
        scrollTopBtn.classList.remove("is-active");
      } // appointmentBar.classList.remove("active");

    }
  });
  observer.observe(header); // Hide appointment bar - local storage

  var hideBar = sessionStorage.nowadeweloperbar;

  if (hideBar) {// appointmentBar.classList.add("hidden");
  } // closeBtn.addEventListener("click", () => {
  //   // appointmentBar.classList.add("hidden");
  //   sessionStorage.nowadeweloperbar = 1;
  // });


  if (!mediaQuery.matches) return;
  var scrollPos = 0;
  window.addEventListener("scroll", function () {
    document.body.getBoundingClientRect().top > scrollPos || scrollPos > 0 ? mainNav.classList.remove("is-scroll") : mainNav.classList.add("is-scroll");
    scrollPos = document.body.getBoundingClientRect().top;
  });
};