import $ from 'jquery';
import mixitup from 'mixitup';
export var initBlog = function initBlog(initAccordion, initAos) {
  var loadMoreBtn = document.querySelector('#load-more');
  var postType = loadMoreBtn.getAttribute('data-post');
  var objectToSend = {
    'action': 'loadmore',
    'page': 1,
    'post-type': postType
  };
  loadMoreBtn.addEventListener('click', function () {
    loadMoreElements();
  }); // Load more elements

  function loadMoreElements() {
    $.ajax({
      url: themeLocalization.ajax_url,
      data: objectToSend,
      type: 'POST',
      success: function success(data) {
        if (data) {
          var newsWrapper = document.querySelector('.blog__list');
          newsWrapper.insertAdjacentHTML('beforeend', data);
          loadMoreBtn.style.display = 'none';
          objectToSend['page']++;
          initAccordion();
          setTimeout(function () {
            initAos();
          }, 100);
        }
      }
    });
  } // Load Elemens by term id


  function loadByTermId(termId) {
    $.ajax({
      url: themeLocalization.ajax_url,
      data: {
        'action': 'loadmorebyid',
        'id': termId ? termId : 'all',
        'post-type': postType
      },
      type: 'POST',
      beforeSend: function beforeSend() {
        document.querySelector('.blog__list').classList.add('is-loading');
      },
      success: function success(data) {
        if (data) {
          var newsWrapper = document.querySelector('.blog__list');
          newsWrapper.innerHTML = data;
          loadMoreBtn.style.display = 'none';
          document.querySelector('.blog__list').classList.remove('is-loading');
          initAccordion();
          setTimeout(function () {
            initAos();
          }, 100);
        }
      }
    });
  } //Filter


  var allFilterBtns = document.querySelectorAll('.blog__nav-btn');
  allFilterBtns.forEach(function (item) {
    item.addEventListener('click', function (e) {
      // Remove active class
      document.querySelector('.blog__nav-btn--active').classList.remove('blog__nav-btn--active');
      var btnId = e.target.getAttribute('data-id');
      e.target.classList.add('blog__nav-btn--active');
      loadByTermId(btnId);
    });
  });
};