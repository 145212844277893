import * as $ from "jquery";
import dt from "datatables.net-dt";
import "datatables.net-responsive";
$.fn.DataTable = dt;
import { updateRellax } from "../main";
export var initTable = function initTable() {
  var housesTable = document.querySelector(".houses-table");
  var flatsNumber = 6;
  var otable = $("#table_id").DataTable({
    responsive: true,
    pageLength: flatsNumber,
    order: [housesTable ? 3 : 5, 'asc'],
    oLanguage: {
      sZeroRecords: "Proszę czekać"
    },
    fnInitComplete: function fnInitComplete(oSettings) {
      oSettings.oLanguage.sZeroRecords = "Brak wyników wyszukiwania!";
    }
  });
  new $.fn.dataTable.Responsive(otable, {
    responsive: true,
    details: false
  }); //Show all rows

  var btnTable = document.querySelector("#table__btn-all");

  function showAllRows(numberRows) {
    var oSettings = otable.fnSettings();
    oSettings._iDisplayLength = numberRows;
    otable.fnDraw();
  }

  if (btnTable) {
    btnTable.addEventListener("click", function (e) {
      updateRellax();
      e.preventDefault();
      var activeElement = e.target;

      if (activeElement.classList.contains("table__btn--active")) {
        showAllRows(flatsNumber);
        activeElement.classList.remove("table__btn--active");
        activeElement.classList.remove("btn__icon-right--up");
        activeElement.classList.add("btn__icon-right--down");
        activeElement.childNodes[2].textContent = "POKAŻ WSZYSTKIE";
        activeElement.href = "#flats-table";
      } else {
        showAllRows(-1);
        activeElement.classList.add("table__btn--active");
        activeElement.classList.add("btn__icon-right--up");
        activeElement.classList.remove("btn__icon-right--down");
        activeElement.childNodes[2].textContent = "POKAŻ MNIEJ";
        activeElement.href = "";
      }
    });
  } //Filter columns


  var filterBtn = document.querySelector(".select-boxes__btn");
  var allSearchSelect = document.querySelectorAll(".select-boxes select");

  if (housesTable) {
    filterBtn.addEventListener("click", function () {
      allSearchSelect.forEach(function (item) {
        if (item.dataset.number == 1) {
          otable.fnDraw();
        } else {
          if (item.value != "Wybierz") {
            otable.fnFilter(item.value, item.dataset.number);
          }
        }
      });
    });
    $.fn.dataTableExt.afnFiltering.push(function (oSettings, aData, iDataIndex) {
      var iMin = document.getElementById("min").value * 1;
      var iMax = document.getElementById("max").value * 1;

      if (isNaN(iMin)) {
        iMin = 0;
      }

      if (isNaN(iMax)) {
        iMax = 200;
      }

      var iVersion = aData[1].slice(0, 3) * 1;

      if (iMin == "" && iMax == "") {
        return true;
      } else if (iMin == "" && iVersion <= iMax) {
        return true;
      } else if (iMin <= iVersion && "" == iMax) {
        return true;
      } else if (iMin <= iVersion && iVersion <= iMax) {
        return true;
      }

      return false;
    });
  } else {
    filterBtn.addEventListener("click", function () {
      updateRellax();
      allSearchSelect.forEach(function (item) {
        if (item.dataset.number == 2) {
          otable.fnDraw();
        } else {
          if (!isNaN(item.value)) {
            otable.fnFilter(item.value * 1, item.dataset.number);
          } else if (item.value == "Parter") {
            otable.fnFilter(item.value, item.dataset.number);
          } else if (item.dataset.number == 0) {
            var regexForFirstInput = "^".concat(item.value, "$");
            otable.fnFilter(regexForFirstInput, item.dataset.number, true, false, false, false);
          }
        }
      });
    });
    $.fn.dataTableExt.afnFiltering.push(function (oSettings, aData, iDataIndex) {
      var iMin = document.getElementById("min").value * 1;
      var iMax = document.getElementById("max").value * 1;

      if (isNaN(iMin)) {
        iMin = 0;
      }

      if (isNaN(iMax)) {
        iMax = 100;
      }

      var iVersion = aData[2].slice(0, 2);

      if (iMin == "" && iMax == "") {
        return true;
      } else if (iMin == "" && iVersion <= iMax) {
        return true;
      } else if (iMin <= iVersion && "" == iMax) {
        return true;
      } else if (iMin <= iVersion && iVersion <= iMax) {
        return true;
      }

      return false;
    });
  }
};