import jQuery from "jquery";
window.jQuery = jQuery;

var PhotoSwipe = require("photoswipe");

var PhotoSwipeUI_Default = require("photoswipe/dist/photoswipe-ui-default"); // Edited


export var initGallery = function initGallery() {
  (function ($) {
    var $pswp = $(".pswp")[0];
    var image = [];
    $(".pswp__gallery").each(function () {
      var $pic = $(this);

      var getItems = function getItems() {
        var items = [];
        $pic.find("a").each(function () {
          var $href = $(this).attr("href");
          var $size = $(this).data("size").split("x");
          var $title = $(this).data("title");
          var $width = $size[0];
          var $height = $size[1];
          var item = {
            src: $href,
            w: $width,
            h: $height,
            title: $title
          };
          items.push(item);
        });
        return items;
      };

      var items = getItems();
      $.each(items, function (index, value) {
        image[index] = new Image();
        image[index].src = value.src;
      });
      $pic.on("click", ".pswp_link", function (event) {
        event.preventDefault();
        var $index = 0;
        var options = {
          index: $index,
          bgOpacity: 0.85,
          showHideOpacity: true,
          shareEl: false,
          history: false,
          focus: true,
          closeOnScroll: false,
          galleryPIDs: false
        };
        var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
        lightBox.init();
      });
    });
  })(jQuery);
};
export var initLightbox = function initLightbox() {
  (function ($) {
    var $lighboxes = $(".pswp__lightbox");
    $lighboxes.each(function (index, lightbox) {
      var lightboxInstance = $(lightbox); // Init empty gallery array

      var container = []; // Loop over gallery items and push it to the array

      lightboxInstance.find("figure").each(function () {
        var $link = $(this).find("a"),
            item = {
          src: $link.attr("href"),
          w: $link.data("width"),
          h: $link.data("height"),
          class: $link.parent("figure").attr("class")
        }; // if (!item.class.includes('duplicate')) {

        container.push(item); // }
      }); // Define click event on gallery item

      lightboxInstance.find("a").click(function (event) {
        event.preventDefault();
        var $figureEl = $(this).parent("figure");
        var $index = null;

        if ($figureEl.hasClass("fresh-air__swiper-slide")) {
          $index = $figureEl.index() - 1;
        } else {
          $index = $figureEl.index();
        }

        var $pswp = $(".pswp")[0],
            options = {
          index: $index,
          bgOpacity: 0.8,
          captionEl: false,
          tapToClose: true,
          shareEl: false,
          fullscreenEl: false
        }; // Initialize PhotoSwipe

        var gallery = new PhotoSwipe($pswp, PhotoSwipeUI_Default, container, options);
        gallery.init();
      });
    });
  })(jQuery);
};