import AOS from "aos";
import Rellax from "rellax";
import SweetScroll from "sweet-scroll";
import HandyCollapse from "handy-collapse";
import { initHamburgerMenu, initAccordionSubmenus } from "./modules/menu";
import { addClassOnScroll } from "./modules/addClassOnScroll";
import { initSlider, initCardSlider, initNewsSlider, initInvestemntsSlider, initFreshAirSlider, initLooksSlider, initFeaturesSlider } from "./modules/slider";
import { drawingSVG } from "./modules/animations";
import { initTable } from "./modules/table";
import { initGallery, initLightbox } from "./modules/gallery";
import { initVideo } from "./modules/video";
import { initContactForm } from "./modules/contact-form";
import { initNewsletter } from "./modules/newsletter"; // import { initPopupMeeting } from "./modules/popup-meeting";

import $ from "jquery";
import { initBlog } from "./modules/blog";
import { initPopup } from "./modules/popup";
import { newInvest } from "./modules/new-invest";
import { changeNumbers } from "./modules/changeNumbers";
import { mainPopup } from "./modules/main-popup";
var page = document.querySelector("body");
var rellax = null;
var mediaQuery = window.matchMedia("(min-width: 992px)");

function fadeOutEffect() {
  var fadeTarget = document.querySelector(".preloader");
  page.style.overflow = "hidden";
  var fadeEffect = setInterval(function () {
    if (!fadeTarget.style.opacity) {
      fadeTarget.style.opacity = 1;
    }

    if (fadeTarget.style.opacity > 0) {
      fadeTarget.style.opacity -= 0.2;
    } else {
      clearInterval(fadeEffect);
    }
  }, 100);
} // Change title tab


var defaultTitle = document.title;

window.onblur = function () {
  document.title = "Wr\xF3\u0107 do nas | ".concat(defaultTitle);
};

window.onfocus = function () {
  document.title = defaultTitle;
}; // Fix 100vh header mobile


var fixHeader = function fixHeader() {
  var header = document.querySelector(".fix-header");

  if (mediaQuery.matches) {
    var nav = document.querySelector(".navigation");

    if (nav && header) {
      header.style.paddingTop = "".concat(nav.clientHeight, "px");
    }
  } else {
    var _nav = document.querySelector(".nav");

    if (_nav && header) {
      header.style.paddingTop = "".concat(_nav.clientHeight, "px");
    }
  }
};

var initAccordion = function initAccordion() {
  var myAccrodion = new HandyCollapse({
    onSlideStart: function onSlideStart(isOpen, contentID) {
      var buttonEl = document.querySelector("[data-hc-control='".concat(contentID, "']"));
      var buttons = document.querySelectorAll("[data-hc-control='".concat(contentID, "']"));

      if (buttonEl.classList.contains("blog__item-btn")) {
        if (isOpen) {
          buttons.forEach(function (item) {
            item.innerHTML = "zwiń";
          });
        } else {
          buttons.forEach(function (item) {
            item.innerHTML = "więcej";
          });
        }
      }
    }
  });
};

var initAos = function initAos() {
  AOS.init({
    once: true,
    disable: "mobile"
  });
};

document.addEventListener("DOMContentLoaded", function () {
  if (!mediaQuery.matches) {
    initAccordionSubmenus();
  }

  fadeOutEffect();
  initHamburgerMenu();
  fixHeader();
  addClassOnScroll();
  changeNumbers();
  initSlider();
  initInvestemntsSlider();
  initLooksSlider();
  drawingSVG();
  initGallery();
  initLightbox();
  initNewsletter();
  initPopup();
  mainPopup(); // initPopupMeeting();

  if (page.classList.contains("page-template-archive-mieszkania") || page.classList.contains("page-template-new-invest")) {
    var tableSection = document.querySelector(".table");

    if (tableSection) {
      setTimeout(function () {
        $.ajax({
          url: themeLocalization.ajax_url,
          method: "POST",
          data: {
            action: "change_table",
            category: document.querySelector(".table").getAttribute("data-current-cat"),
            table: document.querySelector(".table-url").getAttribute("data-url")
          },
          success: function success(response) {
            document.querySelector(".table__container").outerHTML = response;
            document.querySelector(".table__loader").classList.add("is-loaded");
            initTable();
          },
          error: function error(_error) {
            console.log(_error);
          }
        });
      }, 500);
    }

    initNewsSlider();
  }

  if (page.classList.contains("page-template-deweloper")) {
    initVideo();
  }

  if (page.classList.contains("single-mieszkania") || page.classList.contains("single-domy")) {
    initCardSlider();
    initContactForm("dataLayer");
  }

  if (page.classList.contains("single-mieszkania")) {
    initCardSlider();
  }

  if (page.classList.contains("page-template-contact") || page.classList.contains("page-template-upcoming-investment") || page.classList.contains("page-template-investment") || page.classList.contains("page-template-archive-mieszkania") || page.classList.contains("page-template-new-invest") || page.classList.contains("page-template-new-invest-brown") || page.classList.contains("page-template-new-invest-green") || page.classList.contains("page-template-new-all-investments") || page.classList.contains("page-template-new-invest-sosnowiec")) {
    initContactForm("dataLayer");
  }

  if (page.classList.contains("page-template-page-form")) {
    initContactForm("dataLayer");
  }

  if (page.classList.contains("page-template-new-invest-sosnowiec")) {
    initFeaturesSlider();
  }

  if (page.classList.contains("page-template-new-invest") || page.classList.contains("page-template-new-invest-brown") || page.classList.contains("page-template-new-invest-green") || page.classList.contains("page-template-new-invest-houses") || page.classList.contains("page-template-new-invest-sosnowiec")) {
    initFreshAirSlider();
    newInvest();
  }

  if (page.classList.contains("post-type-archive-blog") || page.classList.contains("post-type-archive-career")) {
    initBlog(initAccordion, initAos);
  }

  if (page.classList.contains("page-template-archive-domy") || page.classList.contains("page-template-new-invest-houses")) {
    initTable();
    initContactForm("dataLayer");
  }

  initAccordion();

  if (page.classList.contains("post-type-archive-blog")) {
    setTimeout(function () {
      initAos();
    }, 100);
  } else {
    initAos();
  }

  rellax = new Rellax(".rellax", {
    center: true
  });
  var scroller = new SweetScroll({
    easing: "easeOutSine"
  });
  var hash = window.location.hash;
  var needsInitialScroll = document.getElementById(hash.substr(1)) != null;

  if (needsInitialScroll) {
    window.location.hash = "";
  }

  window.addEventListener("load", function () {
    if (needsInitialScroll) {
      scroller.to(hash, {
        updateURL: "replace"
      });
    }
  }, false);

  if (page.classList.contains("single-blog")) {
    // Add event listener to handle clicks on links
    document.addEventListener("click", function (event) {
      var target = event.target;

      if (target.tagName === "A" && target.getAttribute("href").startsWith("#")) {
        event.preventDefault();
        var targetId = target.getAttribute("href");
        scroller.to(targetId, {
          updateURL: "replace"
        });
      }
    });
  }
}, false);
export function updateRellax() {
  if (rellax) {
    setTimeout(function () {
      rellax.refresh();
    }, 1000);
  }
}