import Cookies from "js-cookie";
export var initPopup = function initPopup() {
  var popup = document.querySelector(".popup");
  if (!popup) return;
  var popupCookieDays = popup.dataset.cookie * 1,
      popupCloseBtn = popup.querySelector(".popup__close"),
      popupBg = popup.querySelector(".popup__bg"),
      newsletterPopup = popup.classList.contains("global-popup"),
      btnsCardDownload = document.querySelectorAll(".btn-card-download");

  function addActiveClass(event) {
    popup.classList.add("is-active");
    popup.classList.remove("is-disable");

    if (event) {
      popup.removeEventListener("transitionend", addActiveClass);
    }
  }

  function closePopup(newsletter) {
    if (newsletter) {
      Cookies.set("popup-nowa-deweloper", true, {
        expires: popupCookieDays
      });
    }

    popup.classList.add("is-disable");
  }

  function updateCardLinkInsideInput(cardLink, formCardInput) {
    formCardInput.value = cardLink;
  } // Open newsletter popup


  if (newsletterPopup) {
    popup.addEventListener("transitionend", addActiveClass, false);
  } // Open download cart popup


  if (btnsCardDownload.length) {
    var formCardInput = document.querySelector("#form-card-input");
    btnsCardDownload.forEach(function (btn) {
      btn.addEventListener("click", function () {
        var cardLink = btn.dataset.cardLink;
        addActiveClass();
        updateCardLinkInsideInput(cardLink, formCardInput);
      });
    });
  }

  if (Cookies.get("popup-nowa-deweloper") && newsletterPopup) {
    popup.classList.add("is-disable");
  } else {
    if (newsletterPopup) {
      popup.classList.remove("is-disable");
    }

    popupCloseBtn.onclick = function () {
      closePopup(newsletterPopup);
    };

    popupBg.onclick = function () {
      closePopup(newsletterPopup);
    };
  }
};