import $ from "jquery";
import tippy from "tippy.js";
export var initContactForm = function initContactForm(dataLayer) {
  tippy(".checkbox-group__tooltip", {
    animation: "scale",
    content: function content(reference) {
      return reference.textContent;
    }
  }); // const instance = tippy(
  //   document.querySelector(".acceptance-214 .checkbox-group__tooltip"),
  //   {
  //     animation: "scale",
  //     content(reference) {
  //       return reference.textContent;
  //     },
  //     // allowHTML: true,
  //     maxWidth: 300,
  //     // placement: "bottom-start",
  //     // followCursor: "horizontal",
  //   }
  // );
  // instance.show();

  $(".form-input").focus(function () {
    $(this).parents(".form-group").children(".form-label").addClass("form-label--focused");
  });
  $(".wpcf7-acceptance").each(function () {
    var inputTypeCheckbox = $(this).find(".input__checkbox");
    var labelText = $(this).find(".wpcf7-list-item-label .checkbox-group__tooltip").text();
    $(inputTypeCheckbox).change(function () {
      if (labelText) {
        if (this.checked) {
          inputTypeCheckbox.val(labelText);
        } else {
          inputTypeCheckbox.val(0);
        }
      }
    });
  });
  $(".form-input").blur(function () {
    var inputValue = $(this).val();

    if (inputValue == "") {
      $(this).removeClass("form-input--filled");
      $(this).parents(".form-group").children(".form-label").removeClass("form-label--focused");
    } else {
      $(this).addClass("form-input--filled");
    }
  });
  $(document).bind("wpcf7mailsent", function (event) {
    $(event.target).find(".form-input").removeClass("form-input--filled");
    $(event.target).find(".form-label").removeClass("form-label--focused");
    $(event.target).closest(".wpcf7").css({
      visibility: "hidden",
      opacity: "0"
    });
    $(".contact-form__sent").addClass("active");
    $(".form-header__left-col").addClass("active");

    if (dataLayer) {
      window.dataLayer.push({
        event: "formSubmitted",
        formName: "kontakt"
      });
    }
  }); // Aparment card-> contact form

  var queryString = window.location.search;

  function passUTMParamToInputsValue(param, inputName) {
    var selectInputs = document.querySelectorAll(".wpcf7 [name='".concat(inputName, "']"));

    if (selectInputs.length > 0) {
      selectInputs.forEach(function (selectInput) {
        selectInput.value = param;
      });
    }
  }

  if (queryString) {
    var pageUrl = window.location.origin;
    var urlParams = new URLSearchParams(queryString);
    var card = urlParams.get("karta");
    var apartment = urlParams.get("mieszkanie");

    if (apartment && card) {
      var inputInForm = document.querySelector(".contact-form .input-message");
      inputInForm.focus(function () {
        $(this).parents(".form-group").children(".form-label").addClass("form-label--focused");
      });
      inputInForm.value = "Zapytanie o mieszkanie nr: ".concat(apartment, "\n      Link: ").concat(pageUrl, "/generator-pdf/?karta=").concat(card);
    } // UTM


    sessionStorage.setItem("nowa-deweloper-utm-link", window.location.search);
    var utms = [{
      value: urlParams.get("utm_campaign"),
      name: "utm-campaign"
    }, {
      value: urlParams.get("utm_source"),
      name: "utm-source"
    }, {
      value: urlParams.get("utm_medium"),
      name: "utm-medium"
    }, {
      value: urlParams.get("utm_term"),
      name: "utm-term"
    }, {
      value: urlParams.get("utm_content"),
      name: "utm-content"
    }];
    utms.forEach(function (utm) {
      if (utm.value) {
        passUTMParamToInputsValue(utm.value, utm.name);
        sessionStorage.setItem("nowa-deweloper-".concat(utm.name), utm.value);
      }
    });
  } else {
    var _utms = [{
      value: sessionStorage.getItem("nowa-deweloper-utm-campaign") ? sessionStorage.getItem("nowa-deweloper-utm-campaign") : "",
      name: "utm-campaign"
    }, {
      value: sessionStorage.getItem("nowa-deweloper-utm-source") ? sessionStorage.getItem("nowa-deweloper-utm-source") : "",
      name: "utm-source"
    }, {
      value: sessionStorage.getItem("nowa-deweloper-utm-medium") ? sessionStorage.getItem("nowa-deweloper-utm-medium") : "",
      name: "utm-medium"
    }, {
      value: sessionStorage.getItem("nowa-deweloper-utm-term") ? sessionStorage.getItem("nowa-deweloper-utm-term") : "",
      name: "utm-term"
    }, {
      value: sessionStorage.getItem("nowa-deweloper-utm-content") ? sessionStorage.getItem("nowa-deweloper-utm-content") : "",
      name: "utm-content"
    }];

    _utms.forEach(function (utm) {
      if (utm.value) passUTMParamToInputsValue(utm.value, utm.name);
    });
  } // Add invest name for VOX


  var investInputVOX = document.querySelector("#invest-input-vox");

  if (investInputVOX) {
    var _investNameFromCard = document.querySelector(".card__data-invest-vox");

    if (_investNameFromCard) {
      var dataInvest = _investNameFromCard.textContent.replace(/\s{2,10}/g, " ");

      investInputVOX.value = dataInvest;
    }
  } // Add theme color


  var themeColorInputs = document.querySelectorAll(".wpcf7 .theme__color");

  if (themeColorInputs && themeColorInputs.length > 0) {
    var themeColor = getComputedStyle(document.body).getPropertyValue("--color");
    themeColorInputs.forEach(function (themeColorInput) {
      themeColorInput.value = themeColor;
    });
  } // Invest img


  var investImgInputs = document.querySelectorAll(".wpcf7 .cf7_invest_img");

  if (investImgInputs && investImgInputs.length > 0) {
    var _themeColor = getComputedStyle(document.body).getPropertyValue("--color");

    var investImg = "";

    if (_themeColor === "#924b3a") {
      investImg = "https://nowadeweloper.pl/wp-content/uploads/2024/03/nowy_reden_oferta_online.webp";
    } else if (_themeColor === "#81924d") {
      investImg = "https://nowadeweloper.pl/wp-content/uploads/2024/03/nowa_czestochowa_oferta_online.webp";
    } else if (_themeColor === "#274231") {
      investImg = "https://nowadeweloper.pl/wp-content/uploads/2024/03/e-mail-background.png";
    } else if (_themeColor === "#222949") {
      investImg = "https://nowadeweloper.pl/wp-content/uploads/2024/03/nowa_czestochowa_grabowka_oferta_online.webp";
    } else {
      investImg = "https://nowadeweloper.pl/wp-content/uploads/2024/03/e-mail-background.png";
    }

    investImgInputs.forEach(function (investImgInput) {
      investImgInput.value = investImg;
    });
  } // Add invest name


  var investInput = document.querySelector("#invest-input");
  if (!investInput) return;
  var investName = document.querySelector(".invest-name");
  var investNameFromCard = document.querySelector(".card__data-invest");

  if (investName) {
    var _dataInvest = investName.textContent.replace(/\s{2,10}/g, " ");

    investInput.value = _dataInvest;
  } else if (investNameFromCard) {
    var _dataInvest2 = investNameFromCard.textContent.replace(/\s{2,10}/g, " ");

    investInput.value = _dataInvest2;
  }
};