import Swiper from "swiper/swiper-bundle.min.js";
export var initSlider = function initSlider() {
  var buildingSliderContainer = document.querySelector(".slider .slider__swiper");
  var buildingSlider = new Swiper(buildingSliderContainer, {
    slidesPerView: 1,
    watchSlidesProgress: true,
    centeredSlides: true,
    speed: 800,
    loop: true,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      768: {
        slidesPerView: 3
      }
    }
  });
};
export var initFreshAirSlider = function initFreshAirSlider() {
  new Swiper(".fresh-air__swiper", {
    slidesPerView: 1,
    centeredSlides: true,
    speed: 800,
    loop: true,
    effect: "fade",
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: ".fresh-air__swiper .swiper-button-next",
      prevEl: ".fresh-air__swiper .swiper-button-prev"
    },
    pagination: {
      el: ".fresh-air__swiper .swiper-pagination",
      clickable: true
    }
  });
};
export var initCardSlider = function initCardSlider() {
  var cardSlider = new Swiper(".card__swiper", {
    slidesPerView: 1,
    speed: 800,
    spaceBetween: 20,
    autoHeight: true,
    autoplay: {
      delay: 3000
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      414: {
        slidesPerView: 2
      },
      1280: {
        slidesPerView: 3
      }
    }
  });
};
export var initNewsSlider = function initNewsSlider() {
  var newsSlider = document.querySelector(".news__slider");
  if (!newsSlider) return;
  new Swiper(".news__slider", {
    slidesPerView: 1.3,
    speed: 800,
    spaceBetween: 20,
    centeredSlides: true,
    watchOverflow: true,
    loop: true,
    autoplay: {
      delay: 3000
    },
    navigation: {
      nextEl: ".news__navigation .swiper-button-next",
      prevEl: ".news__navigation .swiper-button-prev"
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
        centeredSlides: false
      },
      1280: {
        slidesPerView: 3
      }
    }
  });
};
export var initInvestemntsSlider = function initInvestemntsSlider() {
  var currentlySaleSection = document.querySelector(".currently-sale");
  if (!currentlySaleSection) return;
  new Swiper(".investemtns__swiper", {
    slidesPerView: 1.2,
    watchSlidesProgress: true,
    centeredSlides: true,
    speed: 900,
    loop: true,
    spaceBetween: 20,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: ".currently-sale .swiper-button-next",
      prevEl: ".currently-sale .swiper-button-prev"
    },
    pagination: {
      el: ".currently-sale .swiper-pagination",
      clickable: true
    },
    breakpoints: {
      768: {
        slidesPerView: 2.3
      },
      992: {
        slidesPerView: 3.4
      }
    }
  });
};
export var initFeaturesSlider = function initFeaturesSlider() {
  var featuresSection = document.querySelector(".features");
  if (!featuresSection) return;
  new Swiper(".features__swiper", {
    slidesPerView: 1.2,
    watchSlidesProgress: true,
    centeredSlides: true,
    speed: 900,
    loop: true,
    spaceBetween: 20,
    autoplay: {
      delay: 5000
    },
    pagination: {
      el: ".features .swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".features .swiper-button-next",
      prevEl: ".features .swiper-button-prev"
    },
    breakpoints: {
      768: {
        slidesPerView: 2.3
      },
      1200: {
        slidesPerView: 3.4
      },
      1600: {
        slidesPerView: 4.4
      }
    }
  });
};
export var initLooksSlider = function initLooksSlider() {
  var looksSliderContainer = document.querySelector(".looks .slider__swiper");
  var looksSlider = new Swiper(looksSliderContainer, {
    slidesPerView: 1,
    watchSlidesProgress: true,
    centeredSlides: false,
    speed: 800,
    loop: true,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        centeredSlides: true
      },
      576: {
        slidesPerView: 2
      }
    }
  });
};